export const censoredKeywords = [
  "best quality",
  "masterpiece",
  "extremely detailed",
  "high quality",
  "absurdres",
  "intricate details",
  "4k",
  "8k",
  "k",
  "score up",
  "unreal engine",
  "kid",
  "kids",
  "underage",
  "magical girl",
  "little girl",
  "litttle",
  "little",
  "lithe",
  "indian child",
  "small child girl",
  "very small child girl",
  "childgirl",
  "littleyearold",
  "children",
  "childhood",
  "child",
  "childs",
  "childmale",
  "childlike",
  "child like",
  "childish",
  "sissy",
  "trap",
  "Shildish",
  "cute",
  "Kawaii",
  "doll",
  "adorable",
  "girl face",
  "Short height",
  "Short breast",
  "Short breasts",
  "small breast",
  "small breasts",
  "smallest breasts",
  "smallest breast",
  "short chest",
  "short body",
  "tiny body",
  "tiny",
  "small body",
  "small chest",
  "small chested",
  "very small",
  "small",
  "mini",
  "micro",
  "microscopic",
  "corps mince",
  "petits seins",
  "petit cul",
  "visage mignon",
  "mignon",
  "petite",
  "slender body",
  "slender chest",
  "slender",
  "delicate body",
  "short limbs",
  "short",
  "imp body",
  "toddler",
  "infant",
  "Infantis",
  "infantil",
  "midget",
  "daughter",
  "baby face",
  "angelic face",
  "baby",
  "little girl",
  "preschooler",
  "highschooler",
  "high schooler",
  "high school",
  "schoolgirl",
  "school girl",
  "middleschooler",
  "middle schooler",
  "middle school",
  "dissected girl",
  "gnome",
  "gnome girl",
  "little boy",
  "dissected boy",
  "boy",
  "boys",
  "son",
  "new born",
  "fetus",
  "under aged",
  "under age",
  "young face",
  "young young",
  "young",
  "younger",
  "jeunes",
  "youngster",
  "youngest",
  "cherubic",
  "undeveloped",
  "immature",
  "jail bait",
  "youth",
  "youthful",
  "minor",
  "minors",
  "junior body",
  "junior",
  "yordle body",
  "yordle",
  "Pré adolescente",
  "Pre adolescente",
  "androgynous",
  "androgyny",
  "adolescent",
  "Adolecente",
  "preteen",
  "chldren",
  "juvenile",
  "kindergartener",
  "elementary schooler",
  "elementary school",
  "middle schooler",
  "high schooler",
  "student",
  "students",
  "breastfeeding",
  "breastfeed",
  "120cm",
  "big head",
  "big face",
  "tea time",
  "play time",
  "playtime",
  "gouhou loli",
  "loli body",
  "loli",
  "chibi",
  "Gouhouloli",
  "lolicon",
  "lolita",
  "singlee shota",
  "single shota",
  "one shota",
  "onee shota",
  "oneee shota",
  "ene shota",
  "man shota",
  "shotaro",
  "shota boy",
  "shota",
  "shotacon",
  "small face",
  "petite face",
  "PetiteInfantgirl",
  "half his size",
  "half the size",
  "childgirl",
  "Infantgirl",
  "oppai loli",
  "loli",
  "tween",
  "petite",
  "prepubescent",
  "teenie",
  "teen",
  "teenager",
  "teenage",
  "teeny",
  "teenybopper",
  "t33n",
  "nigger",
  "chink",
  "gore",
  "liquid fingers",
  "miniature",
  "super thin",
  "slim hip",
  "slim hips",
  "slim thin",
  "slim body",
  "thin",
  "slim",
  "super skinny",
  "very skinny",
  "very little",
  "very skinny",
  "very skinny",
  "androgynos",
  "small shoulders",
  "smallest chest",
  "nearly flat chest",
  "flat chest",
  "chest flat",
  "flat chested",
  "flat breasted",
  "flat cheast",
  "skinny chest",
  "chest is flat",
  "flat",
  "skinny",
  "androgynous girl",
  "deformed mutated disfigured",
  "disfigured",
  "gross whore",
  "slave",
  "death",
  "dead",
  "murdered",
  "strangled",
  "killed",
  "crucified",
  "sacrifice",
  "execution",
  "open surgery",
  "surgery body",
  "surgery bodies",
  "necrophilia",
  "corpse",
  "mortuary",
  "deceased",
  "lifeless",
  "evisceration",
  "angiology",
  "splanchnology",
  "nazi",
  "unconscious",
  "rape",
  "molestation",
  "raped",
  "trying to escape",
  "junkie",
  "drug addict",
  "helpless",
  "fearful",
  "fear",
  "scared",
  "afraid",
  "fainted",
  "faint",
  "traumatized",
  "trauma",
  "red paint",
  "red cum",
  "brown paint",
  "beaten",
  "assault",
  "assaulted",
  "violent",
  "violated",
  "abuse",
  "abused",
  "scratched",
  "being assaulted",
  "nonconsensual",
  "unconsensual",
  "hopeless",
  "terrified",
  "terror",
  "horrified",
  "heavily crying",
  "crying",
  "forcing",
  "forced",
  "distressed",
  "distress",
  "drugged",
  "despair",
  "blood",
  "bloody",
  "blood wound",
  "blood spilling",
  "blood cuts",
  "bloody cuts",
  "bloody cut",
  "scratches",
  "scars",
  "scar",
  "wounds",
  "wounded",
  "whip body marks",
  "whip body mark",
  "slap face marks",
  "slap face mark",
  "whipping marks",
  "whip marks",
  "whip mark",
  "leash marks",
  "leash mark",
  "bony",
  "battered",
  "bruised",
  "bruise",
  "bruises",
  "injury",
  "injuries",
  "cut",
  "cuts",
  "slapping marks",
  "slapping mark",
  "claw marks",
  "claw mark",
  "bite mark",
  "bite marks",
  "suicide",
  "beastiality",
  "bestiality",
  "bestiality",
  "bestiary",
  "bestial",
  "beastly",
  "four legged",
  "four legs",
  "four leg",
  "animalistic",
  "equid race",
  "equid body",
  "equine",
  "Equestrian",
  "equid",
  "mare",
  "horse horse",
  "mare horse",
  "equine horse",
  "ground horse",
  "ground horse",
  "beastial horse",
  "male horse",
  "horse male",
  "horse mare",
  "horse pussy",
  "horses",
  "horse dildo",
  "horse cock",
  "horse",
  "stallion",
  "cheval",
  "Stables",
  "no hands",
  "all four legs",
  "all four leg",
  "four leg",
  "four legs",
  "grotesque",
  "obscene",
  "animal on human",
  "human on animal",
  "actual animal",
  "animal",
  "canine",
  "centaur",
  "inter specice",
  "inter specie",
  "inter species",
  "zoophila",
  "zoophilia",
  "feral",
  "quadrapedal",
  "quadraped",
  "quadruped",
  "pedo",
  "pedophilia",
  "sweet one",
  "sweet two",
  "sweet three",
  "sweet four",
  "sweet five",
  "sweet six",
  "sweet seven",
  "sweet eight",
  "sweet nine",
  "sweet ten",
  "sweet eleven",
  "sweet twelve",
  "sweet thirteen",
  "sweet fourteen",
  "sweet fifteen",
  "sweet sixteen",
  "sweet seventeen",
  "one year old",
  "two year old",
  "three year old",
  "four year old",
  "five year old",
  "six year old",
  "seven year old",
  "eight year old",
  "nine year old",
  "ten year old",
  "eleven year old",
  "twelve year old",
  "thirteen year old",
  "fourteen year old",
  "fifteen year old",
  "sixteen year old",
  "seventeen year old",
  "one years old",
  "two years old",
  "three years old",
  "four years old",
  "five years old",
  "six years old",
  "seven years old",
  "eight years old",
  "nine years old",
  "ten years old",
  "eleven years old",
  "twelve years old",
  "thirteen years old",
  "fourteen years old",
  "fifteen years old",
  "sixteen years old",
  "seventeen years old",
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "one years old",
  "two years old",
  "three years old",
  "four years old",
  "five years old",
  "six years old",
  "seven years old",
  "eight years old",
  "nine years old",
  "ten years old",
  "eleven years old",
  "twelve years old",
  "thirteen years old",
  "fourteen years old",
  "fifteen years old",
  "sixteen years old",
  "seventeen years old",
  "yearl old",
  "years old",
  "age one",
  "age two",
  "age three",
  "age four",
  "age five",
  "age six",
  "age seven",
  "age eight",
  "age nine",
  "age ten",
  "age eleven",
  "age twelve",
  "age thirteen",
  "age fourteen",
  "age fifteen",
  "age sixteen",
  "age seventeen",
  "one age",
  "two age",
  "three age",
  "four age",
  "five age",
  "six age",
  "seven age",
  "eight age",
  "nine age",
  "ten age",
  "eleven age",
  "twelve age",
  "thirteen age",
  "fourteen age",
  "fifteen age",
  "sixteen age",
  "seventeen age",
  "age one",
  "age two",
  "age three",
  "age four",
  "age five",
  "age six",
  "age seven",
  "age eight",
  "age nine",
  "age ten",
  "age eleven",
  "age twelve",
  "age thirteen",
  "age fourteen",
  "age fifteen",
  "age sixteen",
  "age seventeen",
  "age",
  "1 year old",
  "2 year old",
  "3 year old",
  "4 year old",
  "5 year old",
  "6 year old",
  "7 year old",
  "8 year old",
  "9 year old",
  "10 year old",
  "11 year old",
  "12 year old",
  "13 year old",
  "14 year old",
  "15 year old",
  "16 year old",
  "17 year old",
  "year old",
  "1 years old",
  "2 years old",
  "3 years old",
  "4 years old",
  "5 years old",
  "6 years old",
  "7 years old",
  "8 years old",
  "9 years old",
  "10 years old",
  "11 years old",
  "12 years old",
  "13 years old",
  "14 years old",
  "15 years old",
  "16 years old",
  "17 years old",
  "years old",
  "yr old",
  "1 year",
  "2 year",
  "3 year",
  "4 year",
  "5 year",
  "6 year",
  "7 year",
  "8 year",
  "9 year",
  "10 year",
  "11 year",
  "12 year",
  "13 year",
  "14 year",
  "15 year",
  "16 year",
  "17 year",
  "year",
  "1 years",
  "2 years",
  "3 years",
  "4 years",
  "5 years",
  "6 years",
  "7 years",
  "8 years",
  "9 years",
  "10 years",
  "11 years",
  "12 years",
  "13 years",
  "14 years",
  "15 years",
  "16 years",
  "17 years",
  "years",
  "age 1",
  "age 2",
  "age 3",
  "age 4",
  "age 5",
  "age 6",
  "age 7",
  "age 8",
  "age 9",
  "age 10",
  "age 11",
  "age 12",
  "age 13",
  "age 14",
  "age 15",
  "age 16",
  "age 17",
  "1 age",
  "2 age",
  "3 age",
  "4 age",
  "5 age",
  "6 age",
  "7 age",
  "8 age",
  "9 age",
  "10 age",
  "11 age",
  "12 age",
  "13 age",
  "14 age",
  "15 age",
  "16 age",
  "17 age",
  "age",
  "1 yo",
  "2 yo",
  "3 yo",
  "4 yo",
  "5 yo",
  "6 yo",
  "7 yo",
  "8 yo",
  "9 yo",
  "10 yo",
  "11 yo",
  "12 yo",
  "13 yo",
  "14 yo",
  "15 yo",
  "16 yo",
  "17 yo",
  "yo",
  "1 años",
  "2 años",
  "3 años",
  "4 años",
  "5 años",
  "6 años",
  "7 años",
  "8 años",
  "9 años",
  "10 años",
  "11 años",
  "12 años",
  "13 años",
  "14 años",
  "15 años",
  "16 años",
  "17 años",
  "años",
  "1 anos",
  "2 anos",
  "3 anos",
  "4 anos",
  "5 anos",
  "6 anos",
  "7 anos",
  "8 anos",
  "9 anos",
  "10 anos",
  "11 anos",
  "12 anos",
  "13 anos",
  "14 anos",
  "15 anos",
  "16 anos",
  "17 anos",
  "anos",
  "110cm",
  "120cm",
  "schoolboy",
  "tareme",
  "short body",
  "short chest",
  "Serena Pokémon",
  "Serena Pokemon",
  "sarah hyland",
  "sarah ashley",
  "marie rose", //this character is 18+ but looks too young
  "Riley Anderson",
  "riley andersen",
  "Riley from inside out",
  "Riley from insideout",
  "Star butterfly",
  "star vs the forces of evil",
  "skinny mabel",
  "mabel",
  "mabel pines",
  "mabel pines from gravity falls",
  "mabel from gravity falls",
  "gravity falls",
  "pines gravity",
  "pines face",
  "cub",
  "cubs",
  "no breast",
  "no breasts",
  "flat",
  "like boy",
  "flat like boy",
  "flat chest like boy",
  "male like flat chest",
  "men like flat chest",
  "smaller woman",
  "smaller male",
  "smaller men",
  "smaller man",
  "smaller",
  "smallest",
  "boy like",
  "have sex with boy",
  "ロリ",
  "ショタ",
  "少女",
  "中学生",
  "女子高生",
  "未成熟なロリ体型",
  "未熟な身体",
  "貧乳",
  "幼児体形",
  "幼い笑顔",
  "十歳",
  "小学校",
  "小さな胸",
  "平らな胸",
  "幼い顔",
  "ブキュートな顔",
  "痩せている",
  "1 才",
  "2 才",
  "3 才",
  "4 才",
  "5 才",
  "6 才",
  "7 才",
  "8 才",
  "9 才",
  "10 才",
  "11 才",
  "12 才",
  "13 才",
  "14 才",
  "15 才",
  "16 才",
  "17 才",
  "1 歳",
  "2 歳",
  "3 歳",
  "4 歳",
  "5 歳",
  "6 歳",
  "7 歳",
  "8 歳",
  "9 歳",
  "10 歳",
  "11 歳",
  "12 歳",
  "13 歳",
  "14 歳",
  "15 歳",
  "16 歳",
  "17 歳",
  "微乳",
  "幼い",
  "幼顔",
  "学生模样",
  "学生",
  "童貞",
  "若い女",
  "おねショタ",
  "女子高生",
  "中学生",
  "橋本 環奈",
  "高畑 充希",
  "icarly",
  "avril lavigne",
  "Ana de Armas",
  "Angela White",
  "Alicia Vikander",
  "Alicia Florrick",
  "alicia amanda vikander",
  "alison brie",
  "Madeleine Stowe",
  "Alexandria Ocasio Cortez",
  "aot",
  "Audrey Hepburn",
  "Anna popplewell",
  "cassie euphoria",
  "taylor swift",
  "taylor swiift",
  "Krystal Swift",
  "Michelle Obama",
  "melania trump",
  "donald trump",
  "brooke monk",
  "Cobie Smulders",
  "camila cabello",
  "Liv Tyler",
  "Natalie Dormer",
  "Gwyneth Paltrow",
  "Sasha Grey",
  "Kiernan Shipka",
  "Aubrey Plaza",
  "kaley cuoco",
  "Iggy Azalea",
  "t swift",
  "taylo swift",
  "amber heard",
  "ashley graham",
  "ariana grande",
  "Rihanna",
  "Robyn Rihanna Fenty",
  "Robyn Rihanna",
  "Rihanna Fenty",
  "Emma Watson",
  "emma stone",
  "emma laui",
  "Vinnegal",
  "Angelina Jolie",
  "Aishwarya",
  "Erin Burnett",
  "Erin Neff",
  "Kat Denning",
  "kristen stewart",
  "Keira Knightley",
  "Vivica A Fox",
  "Vivica A. Fox",
  "greta thunberg",
  "Kieran Shipka",
  "kat dennings",
  "kat denning",
  "Mitsuki Takahata",
  "naeun",
  "Adele",
  "Kate upton",
  "Kate Mara",
  "Kristen Bell",
  "Laura Bailey",
  "Eva Green",
  "Britney Spears",
  "Jenna Ortega",
  "betty white",
  "Chloe Grace Moretz",
  "Ellen Page",
  "Chloe Moretz",
  "Chloë Grace Moretz",
  "Chloe Grase Moretz",
  "Chloë Moretz",
  "Keri Sable",
  "cara delevingne",
  "Christina Ricci",
  "Caitlin Clark",
  "delevingne",
  "dillion harper",
  "Melissa Benoist",
  "Chloe G. Moretz",
  "Christina Hendricks",
  "Daisy Ridley",
  "lindsay lohan",
  "dawn wells",
  "dakota fanning",
  "Kristen Wiig",
  "Kiera Knightley",
  "Scarlett Johansson",
  "scarlette johansson",
  "Madelaine Petsch",
  "Melissa McCarthy",
  "Natasha Nice",
  "Morena Baccarin",
  "gal gadot",
  "gadot",
  "sydney sweeney",
  "Demi Lovato",
  "Emilia Clarke",
  "margot robbie",
  "Maggie Gyllenhaal",
  "Selena Gomez",
  "sabrina carpenter",
  "Becky G",
  "Scarlett Johansen",
  "Mia Khalifa",
  "Maisie Williams",
  "Sanna Marin",
  "Maria Lionza",
  "Melissa Rauch",
  "Winnie Cooper",
  "Janet Jackson",
  "emily blunt",
  "meika woollard",
  "Famke Janssen",
  "daenerys targaryen",
  "taylor swift",
  "Anya Taylor Joy",
  "bebe rexha",
  "jennette mccurdy",
  "Amanda seyfried",
  "Mila Kunis",
  "Lily Collins",
  "Anne Hathaway",
  "Natalie Portman",
  "Charlyne Yi",
  "Hailee Steinfeld",
  "shania twain",
  "Zoe Kazan",
  "jennette mccurdy",
  "Salma Hayek",
  "Alexis Fawx",
  "Sofia Vergara",
  "victoria justice",
  "Ciara Bravo",
  "Charlotte Gainsbourg",
  "Zosia Mamet",
  "megan fox",
  "tzuyu",
  "Billie Eilish",
  "billie elish",
  "jennifer lawrence",
  "Jennifer Aniston",
  "alexandra daddario",
  "Machine Gun Kelly",
  "Gianna Michaels",
  "Henry cavill",
  "brad pitt",
  "Christiano Ronaldo",
  "Cristiano Ronaldo",
  "Alice March",
  "Samara Weaving",
  "Beyoncé",
  "Beyonce",
  "Mariah Carey",
  "Elizabeth olsen",
  "jodie foster",
  "Dua Lipa",
  "Ashanti",
  "Mary Mouser",
  "hillary clinton",
  "Hilary, clinton",
  "Hilary Duff",
  "Reba McEntire",
  "Hayden Panettiere",
  "Emelia Clarke",
  "Amy Adams",
  "Dolly Parton",
  "Kelly Clarkson",
  "Sanaa Lathan",
  "Sandra Bullock",
  "Tulsi Gabbard",
  "Kelly Rowland",
  "Katey Sagal",
  "Kate Beckinsale",
  "kristina pimenova",
  "Beckinsale",
  "kolinda grabar kitarović",
  "kolinda grabar",
  "Kate Middleton",
  "Marilyn Monroe",
  "Elizabeth Moss",
  "Nancy Pelosi",
  "Kamala Harris",
  "Ivanka Trump",
  "Büsra Atici",
  "Alexandria Ocasio Cortez",
  "Brie Larson",
  "Milana Vayntrub",
  "Sadie Sink",
  "Katy Perry",
  "Halle Berry",
  "Megyn Kelly",
  "Kim Basinger",
  "Kim kardashian",
  "khloe kardashian",
  "kourtney kardashian",
  "kylie jenner",
  "kendall jenner",
  "Jeri Ryan",
  "jessica biel",
  "jessica alba",
  "Jennifer Connelly",
  "Kate beckisale",
  "Sophie rain",
  "elle fanning",
  "Millie Bobby Brown",
  "olivia wilde",
  "carrie fisher",
  "Milla Jovovich",
  "Yuzuki Suwa",
  "Kirnan shipka",
  "Miranda Cosgrove",
  "Marilyn Manson",
  "Ludwig Ahgren",
  "Ludwig",
  "chris evans",
  "harry styles",
  "Haley Bennett",
  "kanna hashimoto",
  "kamala harris",
  "Zendaya",
  "elsa jean",
  "Chris Hemsworth",
  "Channing Tatum",
  "Thomas Brodie sangster",
  "Lakshmi", //religious
  "Durga", //religious
  "smegma",
  "scat",
  "scatt",
  "Shitty",
  "yellow puke",
  "puke",
  "fart",
  "Defecation",
  "Defacation",
  "Defecate",
  "feces",
  "poop",
  "butt pooping",
  "butt poop",
  "pooping",
  "manure",
  "shitting",
  "shit",
  "defecating",
  "defecate",
  "farting",
  "fart",
  "prolapse",
  "prolapsed",
  "prolapsesd",
  "difference in age",
  "age difference",
  "age different",
  "different age",
  "difference in size",
  "size difference",
  "size different",
  "different size",
  "difference size",
  "difference age",
  "difference",
  "opposite size",
  "difference in height",
  "height different",
  "height difference",
  "different height",
  "age gap"
];